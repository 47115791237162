<style>
@import "../../../assets/wise/KB-TRUST.css";
</style>
<template>

  <div id="wrap">
    <div id="popup-loading"  :class="{ 'is-active': isLoading }">
    <template v-if="isLoading">
      <LoadingDiv v-model="isLoading" />
    </template>
    </div>

    <!-- header  -->
    <div id="header">
      <div class="common_area clearfix">
        <a href="#" class="ci_area"
        ><img src="../../../assets/wise/ci.png" alt="KB금융그룹|KB-TRUST"
        /></a>
      </div>
    </div>
    <!--// header  -->
    <!--  메뉴   -->
    <div id="gnb" style="display: none">
      <div id="nav">
        <ul class="smenu">
          <li>
            <a href="javascript:alert('내부망 Wisenet을 이용 해 주세요!!');"
            >그룹 홈</a
            >
          </li>
          <li><a class="on" href="#">칭찬하기</a></li>
          <li>
            <a href="javascript:alert('내부망 Wisenet을 이용 해 주세요!!');"
            >보낸 칭찬</a
            >
          </li>
          <li>
            <a href="javascript:alert('내부망 Wisenet을 이용 해 주세요!!');"
            >받은 칭찬</a
            >
          </li>
          <li>
            <a href="javascript:alert('내부망 Wisenet을 이용 해 주세요!!');"
            >모든 칭찬</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!--//  메뉴  -->

    <!-- container01  -->
    <div id="container01">
      <div class="left-area01">
        <!-- 개인 정보  -->
        <div class="pro_box">
          <div class="p_img f_left type2">
            <ProfileImg :target="session"/>
          </div>
          <p class="p_noti1 f_left">
            <span id="my-name" class="name2">{{session.lrnerNm}} {{session.jbpsNm}} ({{session.jbgdNm}})</span>
            <span id="my-company-name">KB국민은행</span>
            <span id="my-dept-name">{{session.deptNm}}</span>
          </p>
        </div>
        <!--// 개인 정보  -->

        <!-- 칭찬왕 수상이력 - 이력이 없을 경우 영역 자체가 안보이게   -->
        <div class="award1" id="award1" style="display: none">
          <!--- 2021.11.03 추가--->
          <ul class="file-list" id="file-list"></ul>
        </div>
        <!--// 칭찬왕 수상이력  -->

        <!-- 나의 포인트 현황 -->
        <div class="section04">
          <h2>
            <img src="../../../assets/wise/bullet1.png" /> 나의 KBcoin 현황
            <img src="../../../assets/wise/kbcoin.png" />
          </h2>
          <div class="list-box">
            <ul>
              <li>
                선물 가능 KBcoin : <span class="point" id="my-point">{{LrnerInfo.membPoint}}</span>kb
              </li>
              <li>
                선물한 KBcoin : <span class="point" id="sent-point">{{30000 -  LrnerInfo.membPoint }}</span>kb
              </li>
              <li>
                받은 KBcoin 합계 :
                <span class="point" id="recevied-point-sum">{{LrnerInfo.receivedPoint + LrnerInfo.rewardPoint}}</span>kb <br />
                <div class="sub-con">
                  <span
                  >√ 선물 받은 KBcoin :
                    <span class="point" id="received-point">{{LrnerInfo.receivedPoint}}</span>kb
                  </span>
                  <span
                  >√ 칭찬 리워드 :
                    <span class="point" id="reward-point">{{LrnerInfo.rewardPoint}}</span>kb
                  </span>
                  <div class="fon12">
                    ※ 칭찬 선물한 KBcoin의 50%가<br />리워드로 적립됩니다.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--// 나의 포인트 현황 -->

        <!-- 나의 칭찬 현황 -->
        <div class="section04">
          <h2><img src="../../../assets/wise/bullet1.png" /> 나의 칭찬 현황</h2>
          <div class="list-box1">
            <ul>
              <li>
                내가 선물한 칭찬 횟수 :
                <span class="point" id="sent-compliment-cnt">{{ LrnerInfo.sentComplimentCnt }}</span>회 /
                <span class="point" id="sent-people-cnt">{{ LrnerInfo.sentPeopleCnt }}</span>명
              </li>
              <li>
                내가 받은 칭찬 횟수 :
                <span class="point" id="received-compliment-cnt">{{ LrnerInfo.receivedComplimentCnt }}</span>회 /
                <span class="point" id="received-people-cnt">{{ LrnerInfo.receivedPeopleCnt }}</span>명
              </li>
            </ul>
          </div>
        </div>
        <!--// 나의 칭찬 현황 -->
      </div>
      <div class="right-area01">
        <div class="content02 clearfix">
          <h2><img src="../../../assets/wise/bullet.png" /> 칭찬 대상 검색</h2>
          <div class="clearfix">
            <!-- 칭찬 대상 설정  -->
            <table class="tType02 type3 f_left" summary="게시판 등록">
              <caption>
                CS 교육자 등록
              </caption>
              <colgroup>
                <col width="23%" />
                <col width="*" />
              </colgroup>
              <tbody>
              <tr>
                <th scope="row">계열사</th>
                <td colspan="2">
                  <div class="selectbox w160">
                    <select  class="w170"  id="company-select"    title="선택하세요."  style="text-indent: 5px;"  >
                      <option value="KBStar" selected="">KB국민은행</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">부서명</th>
                <td colspan="2">
                  <input
                      v-model="search.deptNm"
                      id="dept-name"
                      class="input-text input_W170"
                      style="ime-mode: active"
                      type="text"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">성명</th>
                <td colspan="2">
                  <input
                      v-model="search.lrnerNm"
                      id="user-name"
                      class="input-text input_W170"
                      style="ime-mode: active"
                      type="text"
                  />
                </td>
              </tr>
              </tbody>
            </table>

            <!-- 칭찬 대상 검색 결과  -->
            <div class="arrow f_left">▶▶</div>
            <div class="p_result f_left">
              <div class="p_img f_left">
                <img v-if="targetLrner.lrnerId ===''"
                     id="user-photo"
                     src="../../../assets/wise/people1.jpg"
                />
                <img v-else
                    id="user-photo"
                    :src="targetLrner.thumb"
                />
              </div>
              <p class="p_noti f_left">
                <span id="target-company-name">KB국민은행</span><br />
                <span id="target-dept-name">{{targetLrner.deptNm}}</span><br />
                이름 : <span id="targetuser-name">{{ targetLrner.lrnerNm }}</span><br />
                직위(호칭) : <span id="user-position">{{ targetLrner.jbpsNm}} {{ targetLrner.jbgdNm}} </span>
              </p>
            </div>
            <!--// 칭찬 대상 검색 결과 --->
          </div>

          <div class="clearfix">
            <!-- 버튼   -->
            <div class="kb-btn-wrap02 w300">
              <a
                  @click="openSerchModal"
                  class="kb-btn-03 W40"
              >검색</a
              >
            </div>
            <!--//  버튼   -->
            <!-- 칭찬왕 수상이력 - 이력이 없을 경우 영역 자체가 안보이게   -->
            <div class="award2" id="award2" style="display: none">
              <!--- 2021.11.03 추가--->
              <ul class="file-list2" id="file-list2"></ul>
            </div>
            <!--//  칭찬왕 수상이력  -->
          </div>
          <!--// 칭찬 대상 설정 --->
        </div>

        <div class="content02">
          <h2><img src="../../../assets/wise/bullet.png" /> 칭찬 선물하기</h2>
          <!-- 칭찬 선물하기  -->
          <table
              class="tType02"
              summary="칭찬 선물하기 등록"
              style="line-height: 1.5 !important"
          >
            <caption>
              칭찬 선물하기 등록
            </caption>
            <colgroup>
              <col width="25%" />
              <col width="*" />
            </colgroup>
            <tbody>
            <tr>
              <th scope="row">제목</th>
              <td colspan="2">
                <input
                    v-model="trustInfo.complimentTitle"
                    id="compliment-title"
                    class="input-text input_W1"
                    style="ime-mode: active"
                    type="text"
                />
              </td>
            </tr>
            <tr>
              <th rowspan="2" scope="row">
                KBcoin 선물하기 <br /><img class="img-coin" src="../../../assets/wise/kbcoin.png"/>
              </th>
              <td colspan="2">
                <input
                    v-model="param.pointChkbox"
                    type="radio"
                    class="input-checkbox"
                    id="point-chkbox-y"
                    name="point-chkbox"
                    value="Y"
                    @click="pointRadioChk('Y')"
                />
                <label for="point-chkbox-y">선물함</label>
                <div id="coinselectDiv" class="selectbox w130 vertical-m" style="margin-left: 10px;">
                  <select id="coinselect" class="w150 kb-form-select" :disabled="pointChk" v-model="trustInfo.complimentPoint" @change="coinSelectoption(param.coinSelect)" style="text-indent: 5px;" >
                    <option value="0" selected>선물할 KBcoin</option>
                    <option value="500">500KBcoin</option>
                    <option value="1000">1000KBcoin</option>
                    <option value="1500">1500KBcoin</option>
                    <option value="2000">2000KBcoin</option>
                  </select>
                </div>



                <!--input type="radio" class="input-checkbox" id="point-chkbox-n" name="point-chkbox" value="N"> <label for="point-chkbox-n">선물안함</label-->
                <input
                    v-model="param.pointChkbox"
                    type="radio"
                    class="input-checkbox"
                    id="point-chkbox-n"
                    name="point-chkbox"
                    value="N"
                    @click="pointRadioChk('N')"
                    style="margin-left: 20px"
                />
                <label for="point-chkbox-n">마음선물</label>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <p v-show="pointDescLabel" id="point-noti" class="noti_red" >
                  ※ KBcoin 선물 시, 아래 항목 필수 작성
                </p>
              </td>
            </tr>
            <tr class="point-details" style="display: none">
              <th scope="row">업무구분<br />(칭찬받는 사람 기준)</th>
              <td colspan="2" id="work-type-td">
                <!-- <input type="radio" class="input-checkbox" id="work-type-chkbox1" name="work-type-chkbox" value='1'> <label for="work-type-chkbox1">본인업무</label>
                <input type="radio" class="input-checkbox" id="work-type-chkbox2" name="work-type-chkbox" value='2'> <label for="work-type-chkbox2">부서내 타인 업무</label>
                <input type="radio" class="input-checkbox" id="work-type-chkbox3" name="work-type-chkbox" value='3'> <label for="work-type-chkbox3">타부서 업무</label>
                <input type="radio" class="input-checkbox" id="work-type-chkbox4" name="work-type-chkbox" value='4'> <label for="work-type-chkbox4">타계열사 업무</label> -->
              </td>
            </tr>
            <tr class="point-details" style="display: none">
              <th scope="row">나와의 관계</th>
              <td colspan="2" id="relation-type-td">
                <!-- <input type="radio" class="input-checkbox" id="relation-type-chkbox1" name="relation-type-chkbox" value='1'> <label for="relation-type-chkbox1">같은 부서 직원</label>
                <input type="radio" class="input-checkbox" id="relation-type-chkbox2" name="relation-type-chkbox" value='2'> <label for="relation-type-chkbox2">타부서 직원</label>
                <input type="radio" class="input-checkbox" id="relation-type-chkbox3" name="relation-type-chkbox" value='3'> <label for="relation-type-chkbox3">타그룹/부문/본부 직원</label>
                <input type="radio" class="input-checkbox" id="relation-type-chkbox4" name="relation-type-chkbox" value='4'> <label for="relation-type-chkbox4">타계열사 직원</label> -->
              </td>
            </tr>
            <tr class="point-details" style="display: none">
              <th scope="row">관련업무</th>
              <td colspan="2">
                <input
                    class="input-text input_W1"
                    type="text"
                    id="work-detail"
                    placeholder="ex) ㅇㅇㅇ 고객님 펀드 가입/ ㅇㅇ 시스템 개발 프로젝트"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                칭찬/감사 메시지<br /><br />
                <li>
                    <span class="fon12" id="textLengthCheck">{{complimentMessageCnt}}</span
                    ><span class="fon12" id="textLengthCheckTotal">/1000</span>
                </li>
              </th>
              <td colspan="2">
                  <textarea
                      v-model="trustInfo.complimentMessage"
                      rows="6"
                      class="input_W1"
                      id="compliment-message"
                      @keyup="keyCount"
                      maxlength="1000"
                      placeholder="칭찬 메시지를 구체적으로 작성해 주세요.
[20자 이상] ※ 동일내용 반복 등 KBcoin을 얻기 위한 목적으로 등록한 건의 경우 연말 KBcoin 정산시 관리자에 의해 제외될 수 있음"
                  ></textarea>
              </td>

            </tr>
            </tbody>
          </table>
          <div class="guide_area">
            <p><span class="bold">■ 칭찬 참고 문구 활용 가이드</span><br>
              - 칭찬 대상 직원의 <span class="bold under">행위를 구체적으로 칭찬</span>해주세요.<br>
              - 칭찬/감사 메시지 작성에 활용할 참고 문구는 <span class="bold point" style="color:#69b7b6">‘칭찬 참고 문구’</span>를 클릭해주세요. <br>
              - 문구를 클릭하면 입력됩니다. 본인의 스타일로 수정하여 메시지 앞, 뒤, 중간에 넣으실 수 있습니다.<br>
              ※ 활용예시 : (<span class="bold point" style="color:#69b7b6">참고문구</span> 활용 첫인사) + (<span class="bold under">행위, 노력에 대한 구체적 칭찬</span>) + (<span class="bold point" style="color:#69b7b6">참고문구</span> 활용 끝인사)

            </p>
            <a @click="openExampleModal" class="guide_btn">칭찬<br>참고문구<br>바로가기<br>[클릭]</a>
          </div>
          <!-- 버튼   -->
          <div class="kb-btn-wrap01">
            <a type="button" @click="sendTrust" class="kb-btn-02">등록하기</a>
          </div>
          <!--//  버튼   -->
          <!--// 칭찬 선물하기 --->
        </div>
      </div>


    <div id="copyright">Copyright KB Financial Group. All rights reserved.</div>

    </div>


  </div>
  <WiseTrustSearchModal
      v-model="wiseTrustSearchModal"
      v-model:items="searchList"
      :isLoading="isLoading"
      @selectedLrner="selectedLrner"
  />

  <WiseTrustExampleModal
      v-model="wiseTrustExampleModal"
      :isLoading="isLoading"
      :subTitle = "subTitle"
      :mainTitle = "mainTitle"
      :subExample = "subExample"
      @exampleText="exampleText"
  />
</template>



<script>
import {computed, ref, reactive, watch} from "vue";
import {useStore} from "vuex";
import WiseTrustSearchModal from "@/components/wise/WiseTrustSearchModal";
import ProfileImg from '@/components/common/ProfileImg';
import {
  // ACT_GET_WISE_EXAMPLE_LIST,
  ACT_GET_WISE_INIT_INFO,
  ACT_GET_WISE_SEARCH_LIST,
  ACT_SET_WISE_TRUST, ACT_GET_WISE_SUB_EXAMPLE_LIST
} from "@/store/modules/wise/wise";
import {ACT_GET_PROFILE_IMG_URL, MUT_SET_PROFILE_IMG_URL} from "@/store/modules/my/my";
import {getListFunc} from "@/assets/js/ui.init";
import LoadingDiv from "@/components/common/LoadingDiv";
import {getItem, getTodayDate, lengthCheck} from "@/assets/js/util";
import WiseTrustExampleModal from "@/components/wise/WiseTrustExampleModal";


export default {
  name: "KbTrust",
  components: {
    WiseTrustSearchModal,
    WiseTrustExampleModal,
    ProfileImg,
    LoadingDiv
  },
  setup() {

    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const wiseTrustSearchModal = ref(false);
    const wiseTrustExampleModal = ref(false);
    const isLoading = ref(false);
    const currentDate = getTodayDate('yyyyMMddhhmm');

    const LrnerInfo = reactive({
      membPoint: '0',
      receivedComplimentCnt: '0',
      receivedPeopleCnt: '0',
      receivedPoint:'0',
      rewardPoint: '0',
      sendPoint: '',
      sentComplimentCnt: '0',
      sentPeopleCnt: '0',
      totalreceivedPoint: '0'
    });

    const trustInfo = reactive({
      invokerId: '',
      complimentTargetId: '',
      complimentTitle: '',
      complimentPoint: '0',
      complimentMessage: '',
      workType: '',
      relationType: '',
      workDetail: ''
    });

    const targetLrner = reactive({
      deptNm: '부서',
      lrnerNm: '심쿵비비',
      lrnerId:'',
      jbpsNm:'스타',
      jbgdNm:'프렌즈',
      thumb: ''
    });

    //썸네일 불러오기
    const profileImgUrl = store.dispatch(`my/${ACT_GET_PROFILE_IMG_URL}`, '2161001').then(res => {
      if(res.status && res.status.src ) {
        store.commit(`my/${MUT_SET_PROFILE_IMG_URL}`, res.status.src)
      }
    })
    const searchList = ref([]);
    const initLrnerInfo = ref([]);
    const param = reactive({
      coinSelect: '',
      pointChkbox:'',
      pointChk:'',

    });

    const search = reactive({
      lrnerNm:'',
      deptNm:'',
    });

    const toggles = reactive({
      trnctOpen: false,
      label: "선물할 KBcoin",
    });
    const pointDescLabel = ref(true);

    const pointChk = ref(false);
    const complimentMessageCnt = ref(0);
    const pointRadioChk = (flag)=>{
      if(flag === 'N'){
        param.coinSelect = "0";
        trustInfo.complimentPoint = "0";
        pointChk.value = true;
        pointDescLabel.value = false;


      } else{
        pointChk.value = false;
        pointDescLabel.value = true;
      }
    }
    const coinSelectoption = (coin)=>{
      if(coin !== '0') param.pointChkbox = 'Y';
    }

    const keyCount = (e) => {
      complimentMessageCnt.value =(e.target.value).length;
      // e.target.value = e.target.value.trim().replace(/[^.0-9]/, '');
    }

    const selectedLrner = (recive)=>{
      targetLrner.lrnerId = recive.lrnerId;
      targetLrner.lrnerNm = recive.lrnerNm;
      targetLrner.thumb = recive.thumb;
      targetLrner.jbgdNm = recive.jbgdNm;
      targetLrner.deptNm = recive.deptNm;
      targetLrner.jbpsNm = recive.jbpsNm;

    }
    const mainTitle = ref("");
    const subTitle = ref([]);
    const subExample = ref([]);

    const exampleText = (text)=>{
      trustInfo.complimentMessage = trustInfo.complimentMessage + text;
    }

    const initInfo =  () =>{
      isLoading.value = true;

      if (currentDate >='202412010000' && currentDate <='202412020900'){ // 8월 거래통제
        alert('칭찬시스템 분기말 정산 작업중입니다. \n 12/1 00:00 ~ 12/2 09:00');
        isLoading.value = false;
        window.close();
      }


      if (!session.value.lrnerId){
        alert('로그인 정보가 잘못되었습니다.');
      }else{

         getListFunc(`wise/${ACT_GET_WISE_INIT_INFO}`, { lrnerId:session.value.lrnerId}, initLrnerInfo, null, () => {

           LrnerInfo.membPoint= initLrnerInfo.value[0].membPoint;
           LrnerInfo.receivedComplimentCnt= initLrnerInfo.value[0].receivedComplimentCnt;
           LrnerInfo.receivedPeopleCnt= initLrnerInfo.value[0].receivedPeopleCnt;
           LrnerInfo.receivedPoint= initLrnerInfo.value[0].receivedPoint;
           LrnerInfo.rewardPoint= initLrnerInfo.value[0].rewardPoint;
           LrnerInfo.sendPoint= initLrnerInfo.value[0].sendPoint;
           LrnerInfo.sentComplimentCnt= initLrnerInfo.value[0].sentComplimentCnt;
           LrnerInfo.sentPeopleCnt= initLrnerInfo.value[0].sentPeopleCnt;
           LrnerInfo.totalreceivedPoint= initLrnerInfo.value[0].totalreceivedPoint;
          isLoading.value = false;
        }, () => {
          isLoading.value = false;
        });
      }

    }

    const openSerchModal = async () =>{
      isLoading.value = true;

      if (search.lrnerNm === '' && search.deptNm === ''){
        alert('부서명 또는 성명을 입력 후 검색하세요.');
        isLoading.value = false;
      }else{
        await getListFunc(`wise/${ACT_GET_WISE_SEARCH_LIST}`, search, searchList, null, () => {
          wiseTrustSearchModal.value = true;
          isLoading.value = false;

        }, () => {
          isLoading.value = false;
        });
      }

    }

    const openExampleModal = async () =>{
         isLoading.value = true;

      await store.dispatch(`wise/${ACT_GET_WISE_SUB_EXAMPLE_LIST}`, {}).then((res) => {
        if(lengthCheck(res)){
          isLoading.value = false;
          const listItem = getItem(res);
          mainTitle.value = listItem.exampleTitle;
          subTitle.value = listItem.subTitleMap;
          subExample.value = listItem.exampleMap;
        }else{
          isLoading.value = false;
        }
      }).then(()=>{
        wiseTrustExampleModal.value = true;
      })



    }



    const sendTrust = () => {

      if(targetLrner.lrnerId===''){
        alert('칭찬할 직원을 선택해주세요.');
        return;
      }
      if(targetLrner.lrnerId===session.value.lrnerId){
        alert('자신에게 칭찬할 수 없습니다.');
        return;
      }

      if(trustInfo.complimentTitle===''){
        alert('제목을 입력해주세요.');
        return;
      }
      if(param.pointChkbox===''){
        alert('KBcoin 선물여부를 선택해주세요.');
        return;
      }
      if(complimentMessageCnt.value <=20){
        alert('칭찬 메시지를 구체적으로 20자 이상 작성해주세요.');
        return;
      }
      if(param.pointChkbox==='Y' && trustInfo.complimentPoint==='0'){

        alert('선물할 KBcoin을 선택해주세요.');
        return;
      }

      trustInfo.invokerId=session.value.lrnerId;
      trustInfo.complimentTargetId=targetLrner.lrnerId;


      if(trustInfo) {
        // if(param.pointChkbox ==='N' && trustInfo.complimentPoint==='0'){
        //   trustInfo.complimentPoint = 'N';
        // }
        if (!confirm('KBcoin은 등록 후 취소 불가하며, 칭찬 메시지 수정은 1주일 이내에만 가능합니다. 등록하시겠습니까?')) return;

        isLoading.value = true;

        if (trustInfo.complimentPoint > LrnerInfo.membPoint){
          alert("선물 가능한 KBcoin이 부족합니다.");
          isLoading.value = false;
          return ;
        }

        store.dispatch(`wise/${ACT_SET_WISE_TRUST}`, trustInfo).then(res => {


          if(res.result.success){
            isLoading.value = false;
            alert('칭찬이 정상적으로 등록 되었습니다.');
            window.close();
          }else{
            isLoading.value = false;
            alert('칭찬 등록중 오류. 관리자에게 문의해주세요!');
          }
        }).catch(e => {
          isLoading.value = false;
          console.error(e);
        })
      }
    }

    watch(() =>   trustInfo.complimentMessage, () => {
      complimentMessageCnt.value =trustInfo.complimentMessage.length;

    });
    initInfo();

    return {
      toggles,
      session,
      pointChk,
      pointRadioChk,
      coinSelectoption,
      param,
      pointDescLabel,
      keyCount,
      complimentMessageCnt,
      wiseTrustSearchModal,
      wiseTrustExampleModal,
      openSerchModal,
      profileImgUrl,
      search,
      searchList,
      isLoading,
      targetLrner,
      selectedLrner,
      LrnerInfo,
      trustInfo,
      sendTrust,
      exampleText,
      openExampleModal,
      subTitle,
      mainTitle,
      subExample,
    };
  },
};
</script>
